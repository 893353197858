import React from "react";
import Layout from "../../components/Layout";
import styles from "./review.module.scss";
import { Link } from "gatsby";

import Image1 from "../../assets/images/pages/dynamist-experience/photoshoot_1.png";
import Image2 from "../../assets/images/pages/dynamist-experience/photoshoot_2.png";
import Image3 from "../../assets/images/pages/dynamist-experience/photoshoot_3.png";

const ReviewPage = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <img src={Image1} alt="Björn Hemfjäll, Dynamist" className={styles.heroImage} />
        <p className={styles.author}>Björn Hemfjäll, Dynamist</p>
        <h1 className={styles.title}>Balans, utveckling och utmaningar – därför trivs jag på Dynamist</h1>
        <p className={styles.date}>11 februari 2025</p>
        <p className={styles.section}>
          Att hitta en arbetsplats där man får utvecklas, utmanas tekniskt och samtidigt ha en bra balans mellan jobb
          och privatliv kan vara en utmaning. Men för Björn Hemfjäll, systemspecialist på Dynamist, var det just de
          sakerna som gjorde att han sökte sig hit – och varför han trivs så bra idag. Här berättar han om sin resa in
          i DevOps, hur det är att jobba på Dynamist och vad han uppskattar mest med jobbet.
        </p>

        <div className={styles.section}>
          <h2>Hur såg din väg in i IT och DevOps ut?</h2>
          <p>
            Jag har alltid varit intresserad av teknik och började inom IT-support. Efter ett tag kände jag att jag ville
            göra mer och började ta mig an uppgifter som rörde automatisering och infrastruktur. Det var så jag började
            närma mig DevOps. Efter att ha jobbat i olika roller insåg jag att det var det här jag ville fokusera på.
            När jag sökte en arbetsplats där jag kunde utvecklas vidare hittade jag Dynamist – och det kändes helt rätt.
          </p>
        </div>

        <div className={styles.section}>
          <div className={styles.section}>
            <h2>Vad gör du som systemspecialist på Dynamist?</h2>
            <p>
              Mitt jobb handlar om att hjälpa kunder att bygga smartare och säkrare system. Jag sätter mig in i deras
              utmaningar, ser var det finns förbättringsmöjligheter och tar fram lösningar som fungerar för just dem.
              Det kan handla om att automatisera processer, förbättra säkerhet eller optimera infrastrukturen.
            </p>
          </div>
          <img src={Image2} alt="Arbetsdag på Dynamist" className={styles.image} />
        </div>

        <div className={styles.section}>
          <h2>Hur ser en vanlig arbetsdag ut för dig?</h2>
          <p>
            Det varierar beroende på uppdraget, men i grunden handlar det om att lösa tekniska problem, utveckla och
            förbättra system och samarbeta med kundens team. Jag jobbar ofta tillsammans med utvecklare och driftpersonal
            för att se till att systemen är stabila och hållbara.
          </p>
        </div>

        <div className={styles.section}>
          <h2>Vilka teknologier jobbar du mest med?</h2>
          <p>
            Jag jobbar ofta i Linux-miljöer och använder verktyg som Ansible för konfigurationshantering.
            Jag har även mycket erfarenhet av Kubernetes och Terraform, speciellt för att bygga och hantera molninfrastruktur.
          </p>
        </div>

        <div className={styles.section}>
          <h2>Finns det något projekt du är extra stolt över?</h2>
          <p>
            Ja, jag var med i ett projekt för en bank där vi migrerade deras SWIFT-lösning till en ny plattform för att
            möta ISO 20022-standarden. Det var en komplex utmaning med både tekniska och organisatoriska bitar att hantera.
            Att se den nya plattformen fungera smidigt och veta att jag var en del av den förändringen var riktigt kul.
          </p>
          <blockquote className={styles.quote}>
            På Dynamist satsar man verkligen på kompetensutveckling och ser till att alla har möjlighet att växa.
          </blockquote>
          <img src={Image3} alt="Kompetensutveckling på Dynamist" className={styles.image} />
        </div>

        <div className={styles.section}>
          <h2>Hur är kulturen på Dynamist?</h2>
          <p>
            Vi har en avslappnad och inkluderande kultur. Även om vi ofta jobbar ute hos kunder ser vi till att hålla kontakt
            genom regelbundna meet-ups där vi delar erfarenheter och kunskap. Det gör att vi känner en gemenskap, trots att
            vi ibland sitter på olika platser.
          </p>
        </div>

        <div className={styles.section}>
          <h2>Vad var det som fick dig att välja Dynamist?</h2>
          <p>
          Jag ville ha en arbetsplats där jag kunde utvecklas inom DevOps och samtidigt ha en bra balans i livet.
          På Dynamist satsar man verkligen på kompetensutveckling och ser till att alla har möjlighet att växa.
          </p>
        </div>

        <div className={styles.section}>
          <h2>Hur märks det att Dynamist prioriterar balans mellan jobb och privatliv?</h2>
          <p>
            Vi har stor frihet att anpassa våra arbetstider och Dynamist erbjuder bra förmåner som friskvårdsbidrag. Det
            gör det enklare att få ihop livspusslet, oavsett om det handlar om familj, träning eller fritidsintressen.
          </p>
        </div>

        <div className={styles.section}>
          <h2>Hur ser Dynamist på kompetensutveckling?</h2>
          <p>
            Det är en stor del av kulturen här. Vi har en personlig utbildningspott som vi kan använda till kurser och certifieringar.
            Dessutom finns en certifieringsbonus, vilket verkligen visar att kompetensutveckling är prioriterat.
          </p>
          <blockquote className={styles.quote}>
            Det finns en genuin vilja att göra Dynamist till en riktigt bra arbetsplats.
          </blockquote>
        </div>

        <div className={styles.section}>
          <h2>Vad skiljer Dynamist från andra företag du jobbat på?</h2>
          <p>
            Det som sticker ut är att Dynamist är byggt av tekniker för tekniker. Grundarna ville skapa det konsultbolag
            de själva hade velat jobba för – och det märks. Det finns en genuin vilja att göra Dynamist till en riktigt bra arbetsplats.
          </p>
        </div>

        <div className={styles.section}>
          <h2>Vad skulle du säga till någon som funderar på att söka jobb här?</h2>
          <p>
            <Link to="/join-us" className={styles.purpleLink}>Sök!</Link>
            &nbsp;
            Det är en riktigt bra arbetsplats där du får utvecklas och jobba med spännande projekt.
          </p>
        </div>

        <div className={styles.section}>
          <h2>Har du något råd till nya konsulter på Dynamist?</h2>
          <p>
            Var nyfiken och ta för dig, men var inte rädd att be om hjälp. Vi är ett gäng med hög kompetens som gärna
            delar med oss och stöttar varandra.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ReviewPage;